import React from 'react'
import { chartColorCodes } from 'shared/chart/chartColorCodes'
import { PerformancePercentage } from 'interfaces/vessel'
import { Tooltip } from 'react-tooltip'

interface Props {
  isAdvanced?: boolean
  performanceData?: PerformancePercentage
}

interface PerformanceItem {
  name: string
  value: number
  color: string
  unit: string
  tooltipText: string
}

function PerformanceSummary({ isAdvanced = false, performanceData }: Props) {
  const performanceItems: PerformanceItem[] = performanceData
    ? [
        {
          name: 'aboveLimit',
          value: performanceData.aboveLimit,
          color: `rgb(${chartColorCodes.red})`,
          unit: '%',
          tooltipText: 'Above Limit',
        },
        {
          name: 'inRange',
          value: performanceData.inRange,
          color: `rgb(${chartColorCodes.green})`,
          unit: '%',
          tooltipText: 'In Range',
        },
        {
          name: 'belowLimit',
          value: performanceData.belowLimit,
          color: `rgb(${chartColorCodes.orange})`,
          unit: '%',
          tooltipText: 'Below Limit',
        },
        {
          name: 'maneuvering',
          value: performanceData.maneuvering,
          color: `rgb(${chartColorCodes.darkGray})`,
          unit: '%',
          tooltipText: 'Manoeuvring',
        },
      ]
    : []

  if (!isAdvanced) {
    return (
      <div className="flex flex-col items-center w-full">
        <p className="text-grayText mb-2">Upgrade plan</p>
        <div className="w-full h-1.5 rounded-md bg-morning-mist" />
      </div>
    )
  }

  return (
    <div className="w-full">
      <div className="grid grid-cols-4 mb-4 w-full mt-2 text-center">
        {performanceItems.map((item) => (
          <div
            key={item.name}
            className="px-1"
          >
            <p
              className="font-medium"
              style={{ color: item.color }}
            >
              {item.value}%
            </p>
          </div>
        ))}
      </div>
      <div className="flex w-full h-1.5 rounded-md overflow-hidden">
        {performanceItems.map((item) => {
          const tooltipId = `performance-${item.name}-${item.value}-tooltip`

          return (
            <div
              key={item.name}
              style={{
                width: `${item.value}%`,
                backgroundColor: item.color,
              }}
              data-tooltip-id={tooltipId}
              className="h-full cursor-pointer"
            >
              <Tooltip
                id={tooltipId}
                border="1px solid #8990A3"
                content={`${item.value}% ${item.tooltipText}`}
                style={{
                  borderRadius: '8px',
                  backgroundColor: 'white',
                  color: '#5D657A',
                  zIndex: 1000,
                }}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default PerformanceSummary
