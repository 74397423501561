import { Chart as ChartJS, LinearScale, LineElement, Legend, CategoryScale, Tooltip as ChartTooltip } from 'chart.js'
import { VesselData } from 'interfaces/vessel'
import { Chart } from 'react-chartjs-2'
import { RootState, useGetSOGInRangeInTimeQuery } from 'store'
import NoData from 'components/utility/NoData'
import Loader from 'components/Loader'
import { useEffect, useState } from 'react'
import lineChartWorker from 'workers/lineChartWorker'
import { getLineChartDataset } from 'shared/chart/getLineChartDataset'
import getLineChartScaleX from 'shared/chart/getLineChartScaleX'
import getLineChartScaleY from 'shared/chart/getLineChartScaleY'
import getLineChartOptions from 'shared/chart/getLineChartOptions'
import { Scales } from 'interfaces/chart'
import ChartWrapper from 'components/chart/ChartWrapper'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { getLineChartDatatsetHidden } from 'shared/chart/getLineChartDatatsetHidden'
import { getLineChartScaleY1 } from 'shared/chart/getLineChartScaleY1'

ChartJS.register(LinearScale, LineElement, CategoryScale, Legend, ChartTooltip)
ChartJS.defaults.font.family = 'Lato'
ChartJS.defaults.font.size = 14

interface Props {
  vessel: VesselData
  startTime: number
  endTime: number
  offset: number
}

function Speed({ vessel, startTime, endTime, offset }: Props) {
  const dispatch = useAppDispatch()
  const [lineData, setLineData] = useState<any>(null)
  const dataAvailable = true // TODO: check if data is available (probably need changes in backend)
  const chartIsRendering = useAppSelector((state: RootState) => state.app.chartIsRendering)
  const lineChartTickCount = useAppSelector((state: RootState) => state.app.lineChartTickCount)

  const { data: SOGData, isFetching: SOGDataIsFetching } = useGetSOGInRangeInTimeQuery({
    vesselId: vessel.id,
    startTime: startTime,
    endTime: endTime,
  })

  let chartDataAndOptions = null
  const vesselLastUpdated = vessel?.last_updated?._seconds || 0

  if (lineData) {
    const { xlabels, timestamps, dataset1Values, dataset2Values } = lineData

    const datasets: any = []
    if (dataset1Values?.length) {
      const chartLabel = 'Speed Over Ground [knots]'
      datasets.push(getLineChartDataset(chartLabel, dataset1Values, timestamps, vesselLastUpdated, '#118F1E', 'y'))
    }

    const scales: Scales = {}
    if (xlabels?.length) {
      scales.x = getLineChartScaleX(xlabels, lineChartTickCount)
      if (dataset1Values?.length) {
        const chartLabel = 'Speed Over Ground [knots]'
        scales.y = getLineChartScaleY(chartLabel)
        scales.y1 = getLineChartScaleY1(chartLabel)
      }
    }

    chartDataAndOptions = {
      chartData: {
        labels: Array.from({ length: dataset1Values.length || dataset2Values.length }, () => ''),
        datasets,
      },
      chartOptions: getLineChartOptions(scales, timestamps, vesselLastUpdated, 'Performance-Speed', true) as any,
    }
  }

  const loading = chartIsRendering || SOGDataIsFetching || !chartDataAndOptions

  useEffect(() => {
    setLineData(null)
  }, [startTime, endTime])

  useEffect(() => {
    if (SOGDataIsFetching) return
    const worker = new Worker(lineChartWorker)
    worker.postMessage({ graph1: SOGData, graph2: null, tzOffset: offset })
    worker.onmessage = (e: MessageEvent) => {
      setLineData(e.data)
      // This chart with only one dataset renders faster than other charts,
      // therefore we should not let this chart stop loading for other charts (as chartIsRendering is global)
      // dispatch(changeChartIsRendering(false))
    }
    return () => {
      worker.terminate()
    }
  }, [SOGData])

  return (
    <ChartWrapper
      title="Speed [knots]"
      tooltipText="Speed based on selected interval"
      // tooltipId="speed-tooltip"
      className="h-96 Speed"
    >
      {loading || !chartDataAndOptions ? (
        <Loader />
      ) : !dataAvailable ? (
        <NoData
          type="warning"
          title="No data available for selected period"
          text="Please, try different date range."
        />
      ) : (
        <Chart
          options={chartDataAndOptions.chartOptions}
          type="line"
          data={chartDataAndOptions.chartData}
        ></Chart>
      )}
    </ChartWrapper>
  )
}

export default Speed
