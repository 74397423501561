import { Chart as ChartJS, LinearScale, LineElement, CategoryScale, Filler } from 'chart.js'
import { Chart } from 'react-chartjs-2'
import { FuelEfficiencyChart } from '../interfaces/vessel'

ChartJS.register(LinearScale, LineElement, CategoryScale, Filler)

interface Props {
  isPositive: boolean
  chartData?: FuelEfficiencyChart[]
}

function MiniChart({ isPositive, chartData = [] }: Props) {
  const data = chartData.map((point) => point.value)

  const chartConfig = {
    labels: Array(data.length).fill(''),
    datasets: [
      {
        data: data,
        borderColor: !isPositive ? '#118F1E' : '#E11D48',
        backgroundColor: !isPositive ? 'rgba(17, 143, 30, 0.3)' : 'rgba(225, 29, 72, 0.3)',
        fill: false,
        tension: 0.4,
        borderWidth: 1.5,
        pointRadius: 0,
      },
    ],
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
  }

  return (
    <div className="h-10 w-full">
      <Chart
        type="line"
        data={chartConfig}
        options={options}
      />
    </div>
  )
}

export default MiniChart
