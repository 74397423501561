import ReusableInputField from '../components/Input'
import { useLocation, useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import UserIcon from '../assets/user_icon.png'
import DeleteIcon from '../assets/delete_icon_gray.png'
import PasswordIcon from '../assets/password_icon.png'
import Alert, { AlertProps } from '../components/Alert'
import ContentWrapper from '../components/layout/ContentWrapper'
import PageHeading from 'components/typography/PageHeading'
import {
  RootState,
  useAddUserToVesselMutation,
  useDeleteUserMutation,
  useGetAllVesselsQuery,
  useRemoveUserFromVesselMutation,
  useResetPasswordMutation,
} from 'store'
import ToggleSwitch from 'components/form/ToggleSwitch'
import { VesselData } from 'interfaces/vessel'
import LeavePageBlocker from 'components/utility/LeavePageBlocker'
import Button from 'components/Button'
import Loader from 'components/Loader'
import { areObjectsEqual } from 'shared/objects/areObjectsEqual'
import { useAppSelector } from 'hooks/useReduxHooks'

function UserDetails() {
  const location = useLocation()
  const navigate = useNavigate()
  const user = useAppSelector((state: RootState) => {
    return state.auth.user
  })
  const [searchQuery, setSearchQuery] = useState('')
  const [alertProps, setAlertProps] = useState<AlertProps>({
    open: false,
    type: 'default',
    title: '',
    alertText: '',
    eventButtonText: '',
    hasCancelButton: false,
    onCancel: () => {},
    onEvent: () => {},
    onOutsideClick: () => {},
  })
  const [deleteUser, deleteUserStatus] = useDeleteUserMutation()
  const [resetPassword, resetPasswordStatus] = useResetPasswordMutation()
  const [addUserToVessel, addUserToVesselStatus] = useAddUserToVesselMutation()
  const [removeUserFromVessel, removeUserFromVesselStatus] = useRemoveUserFromVesselMutation()
  const { data: vessels, isFetching: vesselsLoading } = useGetAllVesselsQuery(user?.uid || '')
  const [localDataCopy, setLocalDataCopy] = useState<VesselData[]>(vessels || [])
  const [formDataChanged, setFormDataChanged] = useState(false)

  const person = location.state?.userData || {}
  let email = person.email || ''
  let uid = person.uid || ''

  const filteredVessels = localDataCopy?.filter((vessel: VesselData) => {
    if (vessel.company !== person.company) {
      return false;
    }
    const vesselName = vessel.name?.toLowerCase()
    const vesselIMO = vessel.imo
    return (
      vesselName?.includes(searchQuery.toLowerCase()) ||
      (vesselIMO !== undefined && vesselIMO.toString().toLowerCase().includes(searchQuery))
    )
  })

  const handleToggle = (vesselId: string) => {
    const updatedLocalDataCopy = localDataCopy.map((vessel: VesselData) => {
      if (vessel.id === vesselId) {
        return {
          ...vessel,
          user_access: vessel.user_access?.includes(person.uid)
            ? vessel.user_access?.filter((user) => user !== person.uid)
            : [...vessel.user_access!, person.uid],
        }
      }
      return vessel
    })
    if (!areObjectsEqual(vessels, updatedLocalDataCopy)) {
      setFormDataChanged(true)
    } else {
      setFormDataChanged(false)
    }

    setLocalDataCopy(updatedLocalDataCopy)
  }

  const getVesselCountForUser = () => {
    return vessels?.filter(
      (vessel) => 
        vessel.company === person.company && 
        vessel.user_access?.includes(person.uid)
    ).length
  }

  const handleSearchChange = (value: string) => {
    setSearchQuery(value)
  }

  function discardChanges() {
    setLocalDataCopy(vessels || [])
    setFormDataChanged(false)
  }

  function saveChanges() {
    localDataCopy.forEach((vessel: VesselData) => {
      const originalVesselData = vessels?.find((originalVessel: VesselData) => originalVessel.id === vessel.id)
      if (originalVesselData) {
        if (originalVesselData.user_access !== vessel.user_access) {
          const userAccess = vessel.user_access?.includes(person.uid)
          if (userAccess) {
            addUserToVessel({ vesselId: vessel.id, uid: person.uid })
          } else {
            removeUserFromVessel({ vesselId: vessel.id, uid: person.uid })
          }
        }
      }
    })
    setFormDataChanged(false)
  }

  useEffect(() => {
    if (resetPasswordStatus.isSuccess) {
      setAlertProps({
        open: true,
        type: 'success',
        title: 'Password reset sent',
        alertText: `User password reset email has been sent to: ${email}`,
        eventButtonText: 'Close',
        hasCancelButton: false,
        onCancel: handleClose,
        onEvent: handleClose,
      })
    } else if (deleteUserStatus.isError) {
      setAlertProps({
        open: true,
        type: 'error',
        title: 'Error',
        alertText: `Failed to send password reset to this email: ${email}`,
        eventButtonText: 'Close',
        hasCancelButton: false,
        onCancel: handleClose,
        onEvent: handleClose,
      })
    }
  }, [resetPasswordStatus.isLoading])

  useEffect(() => {
    if (deleteUserStatus.isSuccess) {
      setAlertProps({
        open: true,
        type: 'success',
        title: 'User deleted',
        alertText: `User ${email} is deleted`,
        eventButtonText: 'Close',
        hasCancelButton: false,
        onCancel: handleClose,
        onEvent: navigateToUsers,
      })
    } else if (deleteUserStatus.isError) {
      setAlertProps({
        open: true,
        type: 'error',
        title: 'Error',
        alertText: `Failed to delete user with this email: ${email}`,
        eventButtonText: 'Close',
        hasCancelButton: false,
        onCancel: handleClose,
        onEvent: handleClose,
      })
    }
  }, [deleteUserStatus.isLoading])

  const deleteUserAlert = (user: any) => {
    setAlertProps({
      open: true,
      type: 'danger',
      title: 'Delete user',
      alertText: `Do you really want to delete user with this email: ${email}`,
      eventButtonText: 'Delete',
      hasCancelButton: true,
      onCancel: handleClose,
      onEvent: handleUserDeletion,
    })
  }

  const handleUserDeletion = async () => {
    setAlertProps({
      open: true,
      type: 'warning',
      title: 'Hold on...',
      alertText: `Deleting user with this email: ${email}`,
      eventButtonText: 'Close',
      hasCancelButton: false,
      onCancel: handleClose,
      onEvent: handleClose,
    })
    deleteUser(uid)
  }

  const handleClose = () => {
    setAlertProps({
      open: false,
      type: 'default',
      title: '',
      alertText: '',
      eventButtonText: '',
      hasCancelButton: false,
      onCancel: handleClose,
    })
  }

  const resetPasswordAlert = (user: any) => {
    setAlertProps({
      open: true,
      type: 'reset-password',
      title: 'Password reset email',
      alertText: `Send user password reset to this email: ${user.email}`,
      eventButtonText: 'Send',
      hasCancelButton: true,
      onCancel: handleClose,
      onEvent: handlePasswordReset,
    })
  }

  const handlePasswordReset = async () => {
    setAlertProps({
      open: true,
      type: 'reset-password',
      title: 'Hold on...',
      alertText: `Sending user password reset email: ${email}`,
      eventButtonText: 'Close',
      hasCancelButton: false,
      onCancel: handleClose,
      onEvent: handleClose,
    })
    resetPassword(email)
  }

  function navigateToUsers() {
    handleClose()
    navigate('/users')
  }

  return (
    <ContentWrapper>
      <Alert
        open={alertProps.open}
        type={alertProps.type}
        title={alertProps.title}
        alertText={alertProps.alertText}
        eventButtonText={alertProps.eventButtonText}
        hasCancelButton={alertProps.hasCancelButton}
        onCancel={alertProps.onCancel}
        onEvent={alertProps.onEvent}
        onOutsideClick={handleClose}
      />

      <div className="flex flex-col justify-between mb-6">
        <PageHeading>User details</PageHeading>
      </div>

      <div className="bg-white rounded-xl h-40 border border-grayOutlineColor overflow-hidden">
        <div className="flex items-center h-24 w-full px-4 gap-4">
          <div className="flex h-16 w-16 bg-[#F7F8FA] rounded-full border border-grayOutlineColor justify-center items-center">
            <img
              src={UserIcon}
              className="h-auto w-6 "
            ></img>
          </div>

          <div className="flex flex-col">
            <p className="text-lg font-semibold">
              {person.name} {person.surname}
            </p>
            <p className="font-normal text-sm text-grayPlaceholder">{person.email}</p>
          </div>

          <div className="flex h-6 gap-8 ml-auto pr-4">
            <img
              src={DeleteIcon}
              onClick={() => {
                deleteUserAlert(person)
              }}
              className="cursor-pointer"
            ></img>
            <img
              src={PasswordIcon}
              onClick={() => {
                resetPasswordAlert(person)
              }}
              className="cursor-pointer"
            ></img>
            {/* <img src={EditIcon}></img> */}
          </div>
        </div>

        <div className="flex h-16 border-t border-grayOutlineColor px-4 items-center gap-6">
          <div>
            <p className="lg:text-sm text-grayText">Role</p>
            <div className="text-sm lg:text-lg">{person.isAdmin !== null && person.isAdmin ? 'Admin' : 'User'}</div>
          </div>

          <div className="bg-grayOutlineColor h-10 w-px" />

          <div>
            <p className="lg:text-sm text-grayText">Vessels</p>
            <div className="text-sm lg:text-lg">
              {getVesselCountForUser()} of {vessels?.filter(v => v.company === person.company).length}
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-xl flex-grow mt-10 border border-grayOutlineColor overflow-hidden">
        <div className="flex justify-between w-full h-20 border-b border-grayOutlineColor px-4 lg:px-6">
          <div className="flex flex-col justify-center">
            <p className="font-semibold text-sm lg:text-lg">Vessels</p>
            <p className="text-sm lg:text-lg text-grayText">
              {vessels && (
                <>
                  Your fleet consists of {vessels.filter(v => v.company === person.company).length} {
                    vessels.filter(v => v.company === person.company).length > 1 ? 'vessels' : 'vessel'
                  }
                </>
              )}
            </p>
          </div>

          <div className="flex gap-4 justify-center items-center">
            <ReusableInputField
              placeholder="Search vessel"
              width="256px"
              isSearchInput={true}
              value={searchQuery}
              onChange={handleSearchChange}
              className="text-sm lg:text-lg"
            />
          </div>
        </div>

        <div className="bg-[#F7F8FA] w-full h-10 border-b border-grayOutlineColor">
          <div className="flex h-full px-4 lg:px-6">
            <div className="flex-1 flex items-center h-full gap-2">
              <p className="text-grayText">Name</p>
            </div>

            <div className="flex-1 flex items-center h-full">
              <p className="text-grayText">Vessel type</p>
            </div>

            <div className="flex-1 flex items-center h-full ml-auto">
              <p className="text-grayText ml-auto items-end">Enabled</p>
            </div>
          </div>
        </div>

        {vesselsLoading || addUserToVesselStatus.isLoading || removeUserFromVesselStatus.isLoading ? (
          <div className="w-full flex justify-center mt-10 items-center">
            <Loader />
          </div>
        ) : (
          <div className="flex flex-col align-stretch flex-1 w-full h-full overflow-auto">
            <div className="flex w-full flex-col">
              {filteredVessels?.map((vessel: VesselData, index: number) => (
                <div
                  key={index}
                  className="flex h-16 border-b border-grayOutlineColor px-4 lg:px-6"
                >
                  <div className="flex-1 flex flex-col justify-center h-full">
                    <p className="text-sm lg:text-lg">{vessel.name}</p>
                    <p className="font-normal text-sm text-[#667085]">IMO {vessel.imo}</p>
                  </div>

                  <div className="flex-1 flex flex-col justify-center h-full">
                    <p className="text-sm lg:text-lg">{vessel.vessel_type}</p>
                  </div>

                  <div className="flex-1 flex flex-col justify-center items-end h-full w-full">
                    <div className="w-[2.625rem]">
                      <ToggleSwitch
                        value={vessel.user_access?.includes(person.uid)}
                        onChange={() => handleToggle(vessel?.id)}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="flex flex-row justify-end items-center px-4 lg:px-6 h-20 gap-4">
              {formDataChanged && (
                <>
                  <Button
                    onClick={saveChanges}
                    secondary
                    semibold
                    style={{ width: '10rem' }}
                  >
                    Save changes
                  </Button>
                  <Button
                    onClick={discardChanges}
                    primary
                    semibold
                    style={{ width: '10rem' }}
                  >
                    Cancel
                  </Button>
                </>
              )}
            </div>

            <LeavePageBlocker dirty={formDataChanged} />
          </div>
        )}
      </div>
    </ContentWrapper>
  )
}

export default UserDetails
