import BarChart, { BarChartDataWithExtras } from 'components/chart/BarChart'
import DoughnutChart, { DoughnutChartData } from 'components/chart/DoughnutChart'
import { BarChartData, PerformanceBarChartData } from 'interfaces/graphApi'
import { VesselData } from 'interfaces/vessel'
import { useEffect, useMemo, useState } from 'react'
import { chartColorCodes } from 'shared/chart/chartColorCodes'
import { useGetPerformanceBarChartInRangeQuery } from 'store/apis/totalsGraphApi'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import ChartJS from 'chart.js/auto'
import { beaufortColors, getBeaufortColor, getBeaufortTextColor } from 'shared/chart/beaufortColors'

interface Props {
  vessel: VesselData
  startTime: number
  endTime: number
  offset: number
  tooltipText?: string
  tooltipId?: string
}

function Performance({ vessel, startTime, endTime, offset, tooltipText, tooltipId }: Props) {
  const {
    data: apiData,
    isFetching,
    error,
  } = useGetPerformanceBarChartInRangeQuery(
    {
      vesselId: vessel.id,
      mainEngineFlowMeterId: vessel.main_engines[0].flowMeter[0].sensorId,
      isSplit: vessel.main_engines[0].flowMeter[0].split,
      startTime: startTime,
      endTime: endTime,
      massFlowRateUpperThreshold: vessel.main_engines[0].massFlowRateUpperThreshold,
      massFlowRateLowerThreshold: vessel.main_engines[0].massFlowRateLowerThreshold,
    },
    { skip: startTime === 0 || endTime === 0 }
  )

  const hasNonZeroValues = useMemo(() => {
    if (!apiData?.data) return false
    return apiData.data.some(
      (item) =>
        item.value.belowLimit > 0 || item.value.inRange > 0 || item.value.aboveLimit > 0 || item.value.maneuvering > 0
    )
  }, [apiData])

  const dataAvalable = !!apiData?.data && apiData?.data.length > 0 && hasNonZeroValues

  const barChartData: BarChartDataWithExtras[] = useMemo(
    () =>
      apiData
        ? [
            {
              label: 'belowLimit',
              unitOfMeasurement: apiData.unitOfMeasurement,
              unitOfRange: apiData.unitOfRange,
              data: apiData.data.map((item) => ({
                category: item.category,
                value: item.value.belowLimit,
                beaufortNumber: item.value.beaufortNumber,
              })),
              color: `rgb(${chartColorCodes.orange})`,
              title: 'Below Limit',
            },
            {
              label: 'inRange',
              unitOfMeasurement: apiData.unitOfMeasurement,
              unitOfRange: apiData.unitOfRange,
              data: apiData.data.map((item) => ({
                category: item.category,
                value: item.value.inRange,
                beaufortNumber: item.value.beaufortNumber,
              })),
              color: `rgb(${chartColorCodes.green})`,
              title: 'In Range',
            },
            {
              label: 'aboveLimit',
              unitOfMeasurement: apiData.unitOfMeasurement,
              unitOfRange: apiData.unitOfRange,
              data: apiData.data.map((item) => ({
                category: item.category,
                value: item.value.aboveLimit,
                beaufortNumber: item.value.beaufortNumber,
              })),
              color: `rgb(${chartColorCodes.red})`,
              title: 'Above Limit',
            },
            {
              label: 'maneuvering',
              unitOfMeasurement: apiData.unitOfMeasurement,
              unitOfRange: apiData.unitOfRange,
              data: apiData.data.map((item) => ({
                category: item.category,
                value: item.value.maneuvering,
                beaufortNumber: item.value.beaufortNumber,
              })),
              color: `rgb(${chartColorCodes.darkGray})`,
              title: 'Maneuvering',
            },
          ]
        : [],
    [apiData]
  )

  const doughnutChartData: DoughnutChartData[] = useMemo(
    () => [
      {
        name: 'Below Limit',
        value: apiData?.totalPercentage.belowLimit || 0,
        unit: '%',
        color: `rgb(${chartColorCodes.orange})`,
      },
      {
        name: 'In Range',
        value: apiData?.totalPercentage.inRange || 0,
        unit: '%',
        color: `rgb(${chartColorCodes.green})`,
      },
      {
        name: 'Above Limit',
        value: apiData?.totalPercentage.aboveLimit || 0,
        unit: '%',
        color: `rgb(${chartColorCodes.red})`,
      },
      {
        name: 'Maneuvering',
        value: apiData?.totalPercentage.maneuvering || 0,
        unit: '%',
        color: `rgb(${chartColorCodes.darkGray})`,
      },
    ],
    [apiData]
  )

  const plugins = useMemo(
    () => [
      ChartDataLabels,
      {
        id: 'datalabelsConfig',
        beforeDraw: (chart: any) => {
          const ctx = chart.ctx
          ctx.save()
          ctx.fillStyle = '#5D657A'
          ctx.font = '12px Lato'
          ctx.restore()
        },
      },
    ],
    []
  )

  const [showBeaufortNumbers, setShowBeaufortNumbers] = useState(true)

  const chartOptions = useMemo(
    () => ({
      plugins: {
        tooltip: {
          callbacks: {
            afterBody: (context: any[]) => {
              const dataIndex = context[0].dataIndex
              const beaufortNumber = barChartData[0].data[dataIndex].beaufortNumber
              return beaufortNumber !== null && beaufortNumber !== undefined ? `Beaufort: ${beaufortNumber}` : ''
            },
          },
        },
        datalabels: {
          display: (context: any) => {
            if (context.datasetIndex !== barChartData.length - 1) return false
            const dataIndex = context.dataIndex
            const totalValue = barChartData.reduce((sum, dataset) => sum + dataset.data[dataIndex].value, 0)
            return totalValue > 0 && showBeaufortNumbers
          },
          formatter: (value: any, context: any) => {
            const beaufortNumber = barChartData[0].data[context.dataIndex].beaufortNumber
            return beaufortNumber !== null && beaufortNumber !== undefined ? `${beaufortNumber}` : ''
          },
          backgroundColor: (context: any) => {
            const beaufortNumber = barChartData[0].data[context.dataIndex].beaufortNumber
            return getBeaufortColor(beaufortNumber)
          },
          borderRadius: 4,
          padding: { top: 1, bottom: 1, left: 2, right: 2 },
          color: (context: any) => {
            const beaufortNumber = barChartData[0].data[context.dataIndex].beaufortNumber
            return getBeaufortTextColor(beaufortNumber)
          },
          anchor: 'end' as const,
          align: 'top' as const,
          offset: 4,
          rotation: barChartData[0]?.data.length > 30 ? -90 : 0,
          font: {
            size: 14,
            family: 'Lato',
            weight: 'bold' as const,
          },
        },
        legend: {
          position: 'bottom' as const,
          onClick: (e: any, legendItem: any, legend: any) => {
            if (legendItem.text === 'Beaufort Number') {
              setShowBeaufortNumbers(!showBeaufortNumbers)
            } else {
              // Use the default legend click handler for other items
              const index = legendItem.datasetIndex
              const ci = legend.chart
              const meta = ci.getDatasetMeta(index)
              meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null
              ci.update()
            }
          },
          labels: {
            generateLabels: (chart: any) => {
              const originalLabels = ChartJS.defaults.plugins.legend.labels.generateLabels(chart)

              const svgSize = 16;
              const rectWidth = 14;
              const svg = `
                <svg width="${svgSize}" height="${svgSize}" xmlns="http://www.w3.org/2000/svg">
                  <rect x="${(svgSize - rectWidth)/2}" width="${rectWidth}" height="${svgSize}" fill="${beaufortColors[4]}" rx="4"/>
                  <text x="50%" y="53%" dominant-baseline="middle" text-anchor="middle" style="font: 500 14px Lato, sans-serif">4</text>
                </svg>`;
              const img = new Image();
              img.src = 'data:image/svg+xml;base64,' + btoa(svg);

              originalLabels.push({
                text: 'Beaufort Number',
                fillStyle: beaufortColors[4],
                strokeStyle: '#5D657A',
                lineWidth: 0,
                hidden: !showBeaufortNumbers,
                index: originalLabels.length,
                datasetIndex: barChartData.length - 1,
                fontColor: '#5D657A',
                pointStyle: img,
              })

              return originalLabels
            },
            usePointStyle: true,
            pointStyle: 'rectRounded',
          },
        },
      },
    }),
    [barChartData, showBeaufortNumbers]
  )

  return (
    <div className="flex gap-4">
      <div className="w-80 xl:w-96 3xl:w-[28rem]">
        <DoughnutChart
          data={doughnutChartData}
          loading={isFetching}
          title="Summary of Policy Adherence"
          className="h-72"
          unitForLegend
          dataAvailable={dataAvalable}
          label="Percentage"
          tooltipText={tooltipText}
          tooltipId={tooltipId}
        />
      </div>
      <BarChart
        data={barChartData}
        title="Percentage distribution of Policy Adherence"
        loading={isFetching}
        offset={offset}
        className="h-72"
        dataAvailable={dataAvalable}
        maxY={125}
        yAxisText="Percentage distribution"
        hideYAxisLabels={true}
        yAxisTitlePadding={16}
        tooltipText={tooltipText}
        tooltipId={tooltipId}
        showTime={true}
        plugins={plugins}
        options={chartOptions}
      />
    </div>
  )
}

export default Performance
