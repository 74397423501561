export const beaufortColors: { [key: number]: string } = {
  0: '#FFFFFF', // White - Calm
  1: '#98FFB0', // Light Mint Green - Light air
  2: '#90EE90', // Light Green - Light breeze
  3: '#9ACD9A', // Medium Green - Gentle breeze
  4: '#D1E189', // Light Khaki - Moderate breeze
  5: '#F3C986', // Light Orange - Fresh breeze
  6: '#FFB366', // Peach - Strong breeze
  7: '#FFA07A', // Light Salmon - Near gale
  8: '#FF8C69', // Salmon - Gale
  9: '#FF7256', // Light Coral - Strong gale
  10: '#FF6347', // Tomato - Storm
  11: '#FF4C4C', // Light Red - Violent storm
  12: '#FF3333', // Bright Red - Hurricane
}

export const getBeaufortColor = (beaufortNumber: number | null | undefined): string => {
  if (beaufortNumber === null || beaufortNumber === undefined) return '#FFFFFF'
  const roundedNumber = Math.round(beaufortNumber)
  return beaufortColors[roundedNumber] || '#FFFFFF'
}

export const getBeaufortTextColor = (beaufortNumber: number | null | undefined): string => {
  return '#000000'
}
