import { useNavigate } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'
import InfoIcon from '../assets/info_icon.png'
import ContentWrapper from '../components/layout/ContentWrapper'
import { RootState, useGetVesselListQuery } from '../store/'
import { VesselStatsData } from 'interfaces/vessel'
import PageHeading from 'components/typography/PageHeading'
import CustomTable, {
  CustomTableContentRow,
  CustomTableHeaderCol,
  CustomTableSettings,
} from 'components/tables/CustomTable'
import Percentage from 'components/Percentage'
import { useAppSelector } from 'hooks/useReduxHooks'
import { getUTCDateString } from 'shared/date/getUTCDateString'
import { getUTCTimeString } from 'shared/date/getUTCTimeString'
import MiniChart from 'components/MiniChart'
import PerformanceSummary from 'components/PerformanceSummary'
import GridView from 'components/GridView'
import { anonymizeValue, shouldAnonymizeData } from 'utils/anonymizeData'

function Vessels() {
  const user = useAppSelector((state: RootState) => {
    return state.auth.user
  })
  const { data: vesselList, isFetching: vesselListFetching } = useGetVesselListQuery(user?.uid || '')
  const shouldAnonymize = shouldAnonymizeData(user?.email)

  const navigate = useNavigate()

  function handleVesselClick(vessel: VesselStatsData) {
    if (!vessel.id) return

    navigate(`/performance/${vessel.id}/overview`, {
      state: { vesselData: vessel },
    })
  }

  const tableHeaderData: CustomTableHeaderCol[] = [
    {
      id: '0',
      content: <p>Name</p>,
    },
    {
      id: 'fuelEfficiencyChange',
      content: (
        <>
          <p>Fuel efficiency change [%]</p>
          <img
            src={InfoIcon}
            className="w-4 h auto"
            data-tooltip-id="fuel-efficiency-change-tooltip"
            data-tooltip-delay-hide={200}
          ></img>
          <Tooltip
            id="fuel-efficiency-change-tooltip"
            border="1px solid #8990A3"
            content='The "Fuel Efficiency Change [%]" displays a trendline representing data over a 90-day period.'
            style={{
              width: '350px',
              borderRadius: '8px',
              backgroundColor: 'white',
              color: '#5D657A',
            }}
          />
        </>
      ),
    },
    {
      id: 'fuelEfficiency',
      content: (
        <>
          <p>Fuel efficiency [kg/nm]</p>
          <img
            src={InfoIcon}
            className="w-4 h auto"
            data-tooltip-id="fuel-efficiency-tooltip"
            data-tooltip-delay-hide={200}
          ></img>
          <Tooltip
            id="fuel-efficiency-tooltip"
            border="1px solid #8990A3"
            content='The "Fuel efficiency [kg/nm]" values represent fuel consumption per nautical mile, calculated from past 90 days.'
            style={{
              width: '350px',
              borderRadius: '8px',
              backgroundColor: 'white',
              color: '#5D657A',
            }}
          />
        </>
      ),
    },
    {
      id: 'payment_plan',
      content: <p>Payment plan</p>,
    },
    {
      id: 'performance',
      content: (
        <>
          <p>Policy Adherence</p>
          <img
            src={InfoIcon}
            className="w-4 h auto"
            data-tooltip-id="performance-tooltip"
            data-tooltip-delay-hide={200}
          ></img>
          <Tooltip
            id="performance-tooltip"
            border="1px solid #8990A3"
            content="Percentage distribution of time spent by the vessel at various power levels according to Company Policy, based on data from the past 90 days"
            style={{
              width: '350px',
              borderRadius: '8px',
              backgroundColor: 'white',
              color: '#5D657A',
            }}
          />
        </>
      ),
    },
    {
      id: 'lastUpdated',
      content: <p className="">Last updated</p>,
    },
  ]

  const tableRowData: CustomTableContentRow[] =
    vesselList?.map((vessel, index) => {
      const timestamp = vessel.last_updated?._seconds || 0
      const lastUpdated =
        timestamp > 0
          ? `${getUTCDateString(new Date(timestamp * 1000))}, ${getUTCTimeString(new Date(timestamp * 1000))}`
          : 'No data available'

      return {
        id: vessel.id.toString(),
        haystack: [
          shouldAnonymize ? anonymizeValue(vessel.name) : vessel.name.toLowerCase(),
          shouldAnonymize ? anonymizeValue(vessel.imo) : vessel.imo?.toString() || '',
        ],
        onRowClick: () => handleVesselClick(vessel),
        cols: [
          {
            id: 'name',
            value: shouldAnonymize ? anonymizeValue(vessel.name) : vessel.name,
            content: (
              <>
                <div>
                  <p className="mb-1 uppercase">{shouldAnonymize ? anonymizeValue(vessel.name) : vessel.name}</p>
                  <p className="text-grayText text-sm">
                    IMO {shouldAnonymize ? anonymizeValue(vessel.imo) : vessel.imo}
                  </p>
                  <p className="text-grayText text-sm">
                    {shouldAnonymize ? anonymizeValue(vessel.vessel_type) : vessel.vessel_type}
                  </p>
                </div>
              </>
            ),
          },
          {
            id: 'fuelEfficiencyChange',
            value: vessel.performance_data_90d?.fuel_efficiency_trend?.toFixed(2) ?? 0,
            content: (
              <div className="flex flex-col gap-2 h-full">
                <div className="flex gap-2 h-full">
                  <Percentage
                    percentage={parseFloat(vessel.performance_data_90d?.fuel_efficiency_trend?.toFixed(2) ?? '0')}
                    isReversed={true}
                  />
                </div>
              </div>
            ),
          },
          {
            id: 'fuelEfficiency',
            value: vessel.performance_data_90d?.fuel_efficiency_average?.toFixed(2) ?? 0,
            content: (
              <>
                <p>{vessel.performance_data_90d?.fuel_efficiency_average?.toFixed(2) ?? 0}</p>
              </>
            ),
          },
          {
            id: 'paymentPlan',
            value: vessel.payment_plan ?? 'basic',
            content: (
              <div className="">
                <p>
                  {vessel.payment_plan
                    ? vessel.payment_plan.charAt(0).toUpperCase() + vessel.payment_plan.slice(1)
                    : 'Basic'}
                </p>
              </div>
            ),
          },
          {
            id: 'performance',
            value: vessel.performance_data_90d?.performance_percentage?.inRange ?? 0,
            content: (
              <PerformanceSummary
                isAdvanced={vessel.payment_plan === 'advanced'}
                performanceData={vessel.performance_data_90d?.performance_percentage}
              />
            ),
          },
          {
            id: 'lastUpdated',
            value: timestamp,
            content: <p className="">{lastUpdated}</p>,
          },
        ],
      }
    }) || []

  const columnClassNames = [
    'w-1/4 min-w-44',
    'w-1/5 min-w-52',
    'w-1/5 min-w-44 pl-4',
    'w-1/8 min-w-44 pl-4',
    'w-1/3 min-w-52',
    'flex-1 min-w-44 pl-4',
  ]

  const VesselGridView = ({
    data,
    isLoading,
    sortState,
  }: {
    data: CustomTableContentRow[]
    isLoading: boolean
    sortState: { columnIndex: number; isAscending: boolean; isDefaultSorting: boolean }
  }) => {
    const sortedData = [...data].sort((a, b) => {
      if (sortState.isDefaultSorting) {
        const aPaymentPlan = a.cols.find((col) => col.id === 'paymentPlan')
        const bPaymentPlan = b.cols.find((col) => col.id === 'paymentPlan')

        if (aPaymentPlan?.value === 'advanced' && bPaymentPlan?.value !== 'advanced') {
          return -1
        }
        if (aPaymentPlan?.value !== 'advanced' && bPaymentPlan?.value === 'advanced') {
          return 1
        }
      }

      const aVal = a.cols[sortState.columnIndex]?.value
      const bVal = b.cols[sortState.columnIndex]?.value

      if (aVal < bVal) {
        return sortState.isAscending ? -1 : 1
      }
      if (aVal > bVal) {
        return sortState.isAscending ? 1 : -1
      }
      return 0
    })

    const renderVesselCard = (row: CustomTableContentRow) => {
      const nameCol = row.cols.find((col) => col.id === 'name')
      const vesselTypeCol = row.cols.find((col) => col.id === 'vesselType')
      const fuelEfficiencyCol = row.cols.find((col) => col.id === 'fuelEfficiency')
      const fuelEfficiencyChangeCol = row.cols.find((col) => col.id === 'fuelEfficiencyChange')
      const performanceCol = row.cols.find((col) => col.id === 'performance')
      const paymentPlanCol = row.cols.find((col) => col.id === 'paymentPlan')
      const lastUpdatedCol = row.cols.find((col) => col.id === 'lastUpdated')

      return (
        <div
          className="bg-white rounded-xl p-6 border border-grayOutlineColor hover:shadow-lg transition-shadow cursor-pointer"
          onClick={row.onRowClick}
        >
          <div className="flex justify-between items-start mb-6">
            <div>{nameCol?.content}</div>
            <div>
              <p className="text-grayText text-sm text-right">{lastUpdatedCol?.content}</p>
            </div>
          </div>

          <div className="h-[1px] bg-grayOutlineColor my-4 mx-[-1.5rem]"></div>

          <div className="mb-6">
            <p className="flex text-sm text-grayText mb-1">Fuel efficiency change [%]</p>
            {fuelEfficiencyChangeCol?.content}
          </div>

          <div className="space-y-4">
            <div>
              <p className="text-sm text-grayText mb-1">Fuel efficiency [kg/nm]</p>
              <p>{fuelEfficiencyCol?.content}</p>
            </div>

            <div>
              <p className="text-sm text-grayText mb-1">Payment plan</p>
              <p>{paymentPlanCol?.content}</p>
            </div>

            {paymentPlanCol?.value === 'advanced' && (
              <div>
                <p className="text-sm text-grayText mb-1">Policy Adherence</p>
                {performanceCol?.content}
              </div>
            )}
          </div>
        </div>
      )
    }

    return (
      <GridView
        data={sortedData}
        isLoading={isLoading}
        settings={{
          gridClassName: 'grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 p-6',
          renderCard: renderVesselCard,
        }}
      />
    )
  }

  const tableSettings: CustomTableSettings = {
    heading: 'Vessels',
    text: `Your fleet consists of ${tableRowData.length} vessels`,
    searchable: true,
    sortable: true,
    horizontallyScrollable: true,
    columnExtraClassNames: columnClassNames,
    defaultSort: {
      columnId: 'paymentPlan',
      value: 'advanced',
      priority: 'high',
    },
    showViewModeSelector: true,
    gridViewComponent: VesselGridView,
  }

  return (
    <ContentWrapper>
      <div className="flex justify-between mb-6">
        <PageHeading>Fleet overview</PageHeading>
      </div>

      <CustomTable
        settings={tableSettings}
        headerData={tableHeaderData}
        rowData={tableRowData}
        isLoading={vesselListFetching}
      />
    </ContentWrapper>
  )
}

export default Vessels
